// Import necessary components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Button, Chip } from "@mui/material";

// Sample data for attempt history
const attempts = [
  {
    attemptTime: "12-10-2024 12:31 PM",
    taskName: "Listening Single Answer SA #1",
    score: 0,
    total: 1,
  },
  {
    attemptTime: "07-10-2024 12:00 PM",
    taskName: "Reading Fill in Blanks SB #2",
    score: 1,
    total: 5,
  },
];

function BillingInformation() {
  return (
    <Card id="attempt-history">
      <SoftBox pt={3} px={2}>
        <SoftTypography variant="h5" fontWeight="medium">
          Attempt History
        </SoftTypography>
      </SoftBox>

      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {attempts.map((attempt, index) => (
            <SoftBox
              key={index}
              component="li"
              mb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              borderRadius="lg"
              sx={{
                backgroundColor: "#F8F9FA",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="caption" fontWeight="regular" color="black">
                  Attempted: {attempt.attemptTime}
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="medium" color="error">
                  {attempt.taskName}
                </SoftTypography>
              </SoftBox>

              <SoftBox display="flex" alignItems="center">
                <Chip
                  label={`${attempt.score}/${attempt.total}`}
                  color={attempt.score === attempt.total ? "success" : "error"}
                  variant="outlined"
                  sx={{
                    color: "white",
                    borderColor: attempt.score === attempt.total ? "#4CAF50" : "#F44336",
                    mr: 2,
                  }}
                />
                {/* <Button variant="contained" color="success">
                  <i className="fa fa-info" />
                </Button> */}
              </SoftBox>
            </SoftBox>
          ))}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default BillingInformation;
