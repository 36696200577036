import React, { useState } from 'react';
import { Tabs, Tab, Card, Typography, Box } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import HearingIcon from '@mui/icons-material/Hearing';
import EditIcon from '@mui/icons-material/Edit';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Chip } from '@mui/material'; // Import Chip from Material UI


import "../../style.css";

import { useNavigate } from 'react-router-dom';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Dashboard() {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();  // Initialize useNavigate hook

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCardClick = (message) => {
    alert(message);
  };

  // Modify the handleCardClick to navigate instead of alerting
  const handleCardClickpath = (path) => {
    navigate(path);  // Use navigate to go to the desired page
  };

  // Speaking section items
  const speakingItems = [
    { label: "Read Aloud", path: "/readaloud", chips: ["New", "Prediction", "Exam", "VIP"] },
    { label: "Repeat Sentence", message: "Repeat Sentence card clicked!", chips: ["New", "VIP"] },
    { label: "Describe Image", message: "Describe Image card clicked!" },
    { label: "Re-tell Lecture", message: "Re-tell Lecture card clicked!" },
    { label: "Short Answer", message: "Short Answer card clicked!" },
    { label: "Respond to Situation", message: "Respond to Situation card clicked!" },
  ];

  // Writing section items
  const writingItems = [
    { label: "Essay", message: "Essay card clicked!" },
    { label: "Summarize Text Acedimic", message: "Summarize Text card clicked!" },
    { label: "Write Email", message: "Write Email card clicked!" },
    { label: "Summarize text Core", message: "Summarize text card clicked!" },
  ];

  // Reading section items
  const readingItems = [
    { label: "Single Answer", message: "Single Answer card clicked!" },
    { label: "Multiple Answers", message: "Multiple Answers card clicked!" },
    { label: "Reorder Paragraph", message: "Reorder Paragraph card clicked!" },
    { label: "Fill in Blanks", message: "Fill in Blanks card clicked!" },
    { label: "R/W Fill in Blanks", message: "R/W Fill in Blanks card clicked!" },
  ];

  // Listening section items
  const listeningItems = [
    { label: "Single Answer", message: "Single Answer card clicked!" },
    { label: "Multi Answer", message: "Multi Answer card clicked!" },
    { label: "Write from Dictation", message: "Write from Dictation card clicked!" },
    { label: "Highlight Summary Academic", message: "Highlight Summary card clicked!" },
    { label: "Incorrect Words", message: "Incorrect Words card clicked!" },
    { label: "Fill in the blanks", message: "Fill in the blanks card clicked!" },
    { label: "Summarize Text Academic", message: "Summarize Text Academic card clicked!" },
    { label: "Missing Word", message: "Missing Word card clicked!" },
    { label: "Summarize Text Core", message: "Summarize Text Core card clicked!" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          indicatorColor="none"
        >
          <Tab
            className='tabresponsive'
            icon={<CampaignIcon sx={{ backgroundColor: "white", color: "white", borderRadius: "5px", width: "20%", height: "30px" }} />}
            label="Speaking"
            style={{ minWidth: 80, textTransform: 'none', height: 80, fontWeight: "500", fontSize: "20px", backgroundColor: "#FDC4B6", marginRight: "10px" }}
          />
          <Tab
            className='tabresponsive'
            icon={<EditIcon sx={{ backgroundColor: "white", color: "white", borderRadius: "5px", width: "20%", height: "30px" }} />}
            label="Writing"
            style={{ minWidth: 80, textTransform: 'none', height: 80, fontWeight: "500", fontSize: "20px", backgroundColor: "#B5F0FA", marginRight: "10px" }}
          />
          <Tab
            className='tabresponsive'
            icon={<AutoStoriesIcon sx={{ backgroundColor: "white", color: "white", borderRadius: "5px", width: "20%", height: "30px" }} />}
            label="Reading"
            style={{ minWidth: 80, textTransform: 'none', height: 80, fontWeight: "500", fontSize: "20px", backgroundColor: "#2DCE89", marginRight: "10px" }}
          />
          <Tab
            className='tabresponsive'
            icon={<HearingIcon sx={{ backgroundColor: "white", color: "white", borderRadius: "5px", width: "20%", height: "30px" }} />}
            label="Listening"
            style={{ minWidth: 80, textTransform: 'none', height: 80, fontWeight: "500", fontSize: "20px", backgroundColor: "#ACB0B9" }}
          />
        </Tabs>

        {/* Tab content */}
        <Box sx={{ paddingTop: "22px" }}>
          {activeTab === 0 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Speaking ( AI Scoring )</Typography>
              <Box className="scrollable-section">
                {speakingItems.map((item, index) => (
                  <Card
                    className='innersections'
                    key={index}
                    sx={{ backgroundColor: "#F0F8FF", marginTop: "15px", cursor: "pointer", padding: "16px" }}
                    onClick={() => handleCardClickpath(item.path)}
                  >
                    <Typography variant="body1">
                      {item.label}
                    </Typography>

                    {/* Conditionally render chips only if they exist for the item */}
                    {/* {item.chips && (
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "10px" }}>
                        {item.chips.includes("New") && <Chip label="New" color="warning" sx={{ marginRight: 1 }} />}
                        {item.chips.includes("Prediction") && <Chip label="Prediction" color="success" sx={{ marginRight: 1 }} />}
                        {item.chips.includes("Exam") && <Chip label="Exam" color="info" sx={{ marginRight: 1 }} />}
                        {item.chips.includes("VIP") && <Chip label="VIP" color="secondary" />}
                      </Box>
                    )} */}
                  </Card>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 1 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Writing ( AI Scoring )</Typography>
              <Box className="scrollable-section">
                {writingItems.map((item, index) => (
                  <Card
                    className='innersections'
                    key={index}
                    sx={{ backgroundColor: "#F0F8FF", marginTop: "15px", cursor: "pointer", padding: "16px" }}
                    onClick={() => handleCardClick(item.message)}
                  >
                    <Typography variant="body1">{item.label}</Typography>
                  </Card>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 2 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Reading</Typography>
              <Box className="scrollable-section">
                {readingItems.map((item, index) => (
                  <Card
                    className='innersections'
                    key={index}
                    sx={{ backgroundColor: "#F0F8FF", marginTop: "15px", cursor: "pointer", padding: "16px" }}
                    onClick={() => handleCardClick(item.message)}
                  >
                    <Typography variant="body1">{item.label}</Typography>
                  </Card>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 3 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Listening</Typography>
              <Box className="scrollable-section">
                {listeningItems.map((item, index) => (
                  <Card
                    className='innersections'
                    key={index}
                    sx={{ backgroundColor: "#F0F8FF", marginTop: "15px", cursor: "pointer", padding: "16px" }}
                    onClick={() => handleCardClick(item.message)}
                  >
                    <Typography variant="body1">{item.label}</Typography>
                  </Card>
                ))}
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default Dashboard;
