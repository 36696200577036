import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import QuizIcon from '@mui/icons-material/Quiz';
import RestoreIcon from '@mui/icons-material/Restore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Mocktest from "layouts/mocktest";
import History from "layouts/history";
import Mictestmain from "layouts/mictestmain";


const routes = [
  {
    type: "collapse",
    name: "Practice",
    key: "dashboard",
    route: "/dashboard",
    icon: <LocalLibraryIcon size="14px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Mock Tests",
    key: "tables",
    route: "/mocktest",
    icon: <QuizIcon size="14px" />,
    component: <Mocktest />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "History",
    key: "history",
    route: "/history",
    icon: <RestoreIcon size="14px" />,
    component: <History />,
    noCollapse: true,
  },
  
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <LockOpenIcon size="14px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  
  // {
  //   type: "collapse",
  //   name: "Mic Test",
  //   key: "testmic",
  //   route: "/",
  //   icon: <MicIcon size="14px" />,
  //   component: <MicTest />,
  //   noCollapse: true,
  // },


  {
    type: "collapse",
    name: "Mic Test",
    key: "mictest",
    route: "/mictest",
    icon: <RestoreIcon size="12px" />,
    component: <Mictestmain />,
    noCollapse: true,
  },
];

export default routes;
