export const API_PATH = {
    STUDENTS: {
      LOGIN: "v2/student/login",
      GET_STUDENT_PROFILE: "v2/student/getme"
      // INSTITUTE_LIST: "v2/superAdmin/getInstitutes",
      // INSTITUTE_COUNT: "v2/superAdmin/getInstitutesCount",
      // INSTITUTE_SIGNUP: "v2/superAdmin/instituteSignUp",
      // EDIT_INSTITUTE_DETAILS: 'v2/superAdmin/editInstituteDetails',
      // LEADS: "v2/superAdmin/getLeads",
      // UPDATE_LEAD: "v2/superAdmin/editLead",
      // CHANGE_LEAD_STATUS: "v2/superAdmin/changeLeadStatus",
      // ADD_INSTITUTE_REQUEST: 'v2/superAdmin/instituteRequest',
      // REQUEST_LIST: "v2/superAdmin/getRequests",
      // EDIT_REQUESTS: "v2/superAdmin/editRequests",
      // DELETE_REQUEST: "v2/superAdmin/deleteRequest",
      // ACCEPT_REQUEST: 'v2/superAdmin/instituteSignUp'
    },
  };
  