// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import { useNavigate } from "react-router-dom";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/curved-6.jpg";

import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { useForm } from "react-hook-form";

function SignIn() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      // Send login request to the API
      const response = await postApi(API_PATH.STUDENTS.LOGIN, data);

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isAuthenticated", "true");
        navigate("/dashboard");
      }
    } catch (err) {
      console.error("Login error:", err);
    }
  };

  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your Username and Password to Sign In"
      image={curved9}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)} sx={{ paddingBottom: "100px" }}>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Username
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="username"
            placeholder="username"
            {...register("username", {
              required: "Username is required",
              pattern: {
                // value: /^[a-zA-Z0-9._%+-]+@(gmail|yahoo|mail)\.com$/i,
                message: "Please enter a valid username address",
              },
            })}
            error={!!errors.username}
          />
          {errors.email && (
            <SoftTypography color="error" variant="caption">
              {errors.username.message}
            </SoftTypography>
          )}
        </SoftBox>

        {/* Password Field */}
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Password"
            {...register("password", { required: "Password is required" })}
            error={!!errors.password}
          />
          {errors.password && (
            <SoftTypography color="error" variant="caption">
              {errors.password.message}
            </SoftTypography>
          )}
        </SoftBox>

        {/* Sign In Button */}
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            Sign In
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
