import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from "context";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // Set mini sidenav state based on window width
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    // Listen to window resize events
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
          <SidenavCollapse color={color} name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavCollapse color={color} key={key} name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <SoftTypography key={key} display="block" variant="caption" fontWeight="bold" textTransform="uppercase" opacity={0.6} pl={3} mt={2} mb={1} ml={1}>
          {title}
        </SoftTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  const [studentName, setStudentName] = useState([]);

  console.log(studentName,"hsdjhsvjhgsdjh")
  const fetchStudentProfile = async () => {
    try {
      const profileResponse = await getApi(API_PATH.STUDENTS.GET_STUDENT_PROFILE, {});
      if (profileResponse && profileResponse.data) {
        setStudentName(profileResponse.data.instituteId); // Set the student's name
      }
    } catch (err) {
      console.error("Failed to fetch student profile:", err);
    }
  };

  useEffect(()=>{
    fetchStudentProfile()
  },[])

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>

        {/* Logo and Institute Name Block */}
        <SoftBox component={NavLink} to="/" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {brand && <SoftBox component="img" src={studentName.image} alt="Institute Name" width="5rem" mb={1} />} {/* Logo with margin below */}
          {brandName && (
            <SoftTypography variant="h5" fontWeight="bold">
              {studentName.instituteName}
            </SoftTypography>
          )}
        </SoftBox>
      </SoftBox>
      <Divider />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
