import React, { useState } from 'react';
import { Box, Typography, ButtonBase, IconButton, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Scrollbars } from 'react-custom-scrollbars';
import Select from 'react-select'; // For react-select dropdown
import ReactPaginate from 'react-paginate';
function RepeatSentence() {

  const navigate = useNavigate();
  
  const handleGoBack = () => {
    navigate('/dashboard');
  };

  // Example data for the list (added 15 more questions)
  const questionData = [
    { id: 1090, month: 'JUNE', status: 'New', attempted: 0 },
    { id: 1083, month: 'JUNE', status: 'New', attempted: 1 },
    { id: 1110, month: 'JUNE', status: 'New', attempted: 1 },
    { id: 1109, month: 'JUNE', status: 'New', attempted: 0 },
    { id: 1108, month: 'JUNE', status: 'New', attempted: 0 },
    { id: 1091, month: 'JULY', status: 'New', attempted: 1 },
    { id: 1092, month: 'JULY', status: 'New', attempted: 1 },
    { id: 1093, month: 'JULY', status: 'New', attempted: 0 },
    { id: 1094, month: 'AUGUST', status: 'New', attempted: 0 },
    { id: 1095, month: 'AUGUST', status: 'New', attempted: 1 },
    { id: 1096, month: 'AUGUST', status: 'New', attempted: 0 },
    { id: 1097, month: 'SEPTEMBER', status: 'New', attempted: 1 },
    { id: 1098, month: 'SEPTEMBER', status: 'New', attempted: 0 },
    { id: 1099, month: 'SEPTEMBER', status: 'New', attempted: 1 },
    { id: 1100, month: 'OCTOBER', status: 'New', attempted: 0 },
    { id: 1101, month: 'OCTOBER', status: 'New', attempted: 1 },
    { id: 1102, month: 'OCTOBER', status: 'New', attempted: 0 },
    { id: 1103, month: 'NOVEMBER', status: 'New', attempted: 0 },
    { id: 1104, month: 'NOVEMBER', status: 'New', attempted: 1 },
    { id: 1105, month: 'DECEMBER', status: 'New', attempted: 0 },
  ];

  // Dropdown options for react-select
  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'attempted', label: 'Attempted' },
    { value: 'unattempted', label: 'Unattempted' },
  ];

  // State for selected filter and pagination
  const [selectedFilter, setSelectedFilter] = useState({ value: 'all', label: 'All' });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  // Filter and paginate the questions based on the selected filter and current page
  const filteredQuestions = questionData.filter((question) => {
    if (selectedFilter.value === 'all') return true;
    if (selectedFilter.value === 'attempted') return question.attempted > 0;
    if (selectedFilter.value === 'unattempted') return question.attempted === 0;
    return true;
  });

  // Calculate the page offset and current questions for the page
  const pageCount = Math.ceil(filteredQuestions.length / itemsPerPage);
  const pageOffset = currentPage * itemsPerPage;
  const currentQuestions = filteredQuestions.slice(pageOffset, pageOffset + itemsPerPage);

  // Handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Handle question click (navigate or perform any other action)
  const handleQuestionClick = (id) => {
    // Navigate to a question detail page (example: /questions/:id)
    navigate(`/readaloud/${id}`);
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Top Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#222',
          color: 'white',
        }}
      >
        <IconButton onClick={handleGoBack} sx={{ color: 'white' }}>
          <CloseIcon sx={{ color: '#ffffff' }} />
        </IconButton>
        <Typography sx={{ color: '#ffffff' }} variant="h4">
          Questions
        </Typography>

        {/* Dropdown and Total Count */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Select
            value={selectedFilter}
            onChange={(option) => setSelectedFilter(option)}
            options={filterOptions}
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: '#ffffff',
                color: '#000000',
                width: 150,
                marginRight: 16,
                fontSize: '15px',
              }),
            }}
          />
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            {filteredQuestions.length} Questions
          </Typography>
        </Box>
      </Box>

      {/* Scrollable List */}
      <Scrollbars autoHide style={{ flexGrow: 1 }}>
        <Box sx={{ padding: 2 }}>
          {currentQuestions.map((question, index) => (
            <ButtonBase
              key={question.id}
              onClick={() => handleQuestionClick(question.id)}
              sx={{ width: '100%', textAlign: 'left', mb: 2 }}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box>
                  <Typography variant="body1" sx={{ fontSize: '15px' }}>
                    {index + 1 + pageOffset}. {question.month} #{question.id}
                  </Typography>
                  <Typography sx={{ fontSize: '15px' }} variant="body2">
                    Attempted: {question.attempted}
                  </Typography>
                </Box>
                <Box>
                  <Chip label="New" color="warning" sx={{ marginRight: 1 }} />
                  <Chip label="Prediction" color="success" sx={{ marginRight: 1 }} />
                  {/* <Chip label="Exam" color="info" sx={{ marginRight: 1 }} /> */}
                  {/* <Chip label="VIP" color="secondary" /> */}
                </Box>
              </Box>
            </ButtonBase>
          ))}
        </Box>
      </Scrollbars>

      {/* Bottom Pagination */}
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f5f5f5',
        }}
      >
        <ReactPaginate
          previousLabel={'←'}
          nextLabel={'→'}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          previousClassName={'arrow-item'}
          nextClassName={'arrow-item'}
          previousLinkClassName={'arrow-link'}
          nextLinkClassName={'arrow-link'}
          activeClassName={'active'}
          disabledClassName={'disabled'}
          breakLabel={null} // Remove any break/ellipsis
          pageRangeDisplayed={0} // No page numbers
          marginPagesDisplayed={0} // No margin page numbers
        />
      </Box>
    </Box>
  );
}


export default RepeatSentence;
