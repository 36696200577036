import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import { useSpeechSynthesis } from 'react-speech-kit';

const Readaloudquestions = () => {
  const { speak, cancel, speaking } = useSpeechSynthesis();
  const [countdown, setCountdown] = useState(40);
  const [recording, setRecording] = useState(false);

  const text = "An industry or workplace often has its own terms for certain items, places, or groups of people, and a university is no different. Here we have attempted to explain some of the terms you may come across on our websites that are specific to higher education.";

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => setCountdown(count => count - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handlePlayText = () => {
    speak({ text });
  };

  const handleStopText = () => {
    cancel();
  };

  const handleRecording = () => {
    setRecording(!recording);
  };

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center"
      minHeight="100vh" 
      bgcolor="#1e1e2f" 
      color="#fff"
      p={4}
      sx={{ position: 'relative' }}
    >

        
      {/* Text Display Area */}
      <Box 
        bgcolor="#2e2e3f"
        p={2}
        borderRadius={2}
        mb={2}
        width="80%"
        textAlign="center"
      >
        <Typography variant="h6">{text}</Typography>
      </Box>

      {/* Media Controls */}
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <IconButton onClick={handlePlayText} color="primary" disabled={speaking}>
          <PlayArrowIcon fontSize="large" style={{ color: 'white' }} />
        </IconButton>
        <IconButton onClick={handleStopText} color="secondary" disabled={!speaking}>
          <ReplayIcon fontSize="large" style={{ color: 'white' }} />
        </IconButton>
      </Box>

      {/* Recording Button */}
      <IconButton 
        onClick={handleRecording} 
        color="success" 
        sx={{ 
          bgcolor: recording ? "red" : "green", 
          color: "#fff", 
          width: 60, 
          height: 60, 
          mb: 2 
        }}
      >
        <MicIcon fontSize="large" />
      </IconButton>
      {recording && <Typography>Recording...</Typography>}

      {/* Countdown Timer in Bottom Corner */}
      <Box 
        position="fixed" 
        bottom={16} 
        right={16} 
        display="flex" 
        alignItems="center" 
        gap={1}
      >
        <CircularProgress variant="determinate" value={(countdown / 40) * 100} style={{ color: '#00b0ff' }} />
        <Typography variant="h6" style={{ color: 'white' }}>
          {countdown} sec
        </Typography>
      </Box>
    </Box>
  );
};

export default Readaloudquestions;
