import { useEffect, useState } from "react";
import { Navigate, useLocation, Route, Routes } from "react-router-dom"; // Remove BrowserRouter

import ReadAloudComponent from "./layouts/dashboard/components/readaloud";
import Readaloudquestions from "./layouts/dashboard/questions/readaloudquestions";
import RepeatSentence from "./layouts/dashboard/components/repeatsentence";
import SignIn from "./layouts/authentication/sign-in"; // Import your SignIn component

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import { CacheProvider } from "@emotion/react";
import routes from "routes";
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brand from "assets/images/global_wings.png";

// Helper function to check authentication
const isAuthenticated = () => localStorage.getItem("isAuthenticated") === "true";

// Protected route component
function ProtectedRoute({ children }) {
  return isAuthenticated() ? children : <Navigate to="/signin" />;
}

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const isFullScreenRoute = pathname.startsWith("/readaloud") || pathname.startsWith("/repeatsentence");

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return (
    <ThemeProvider theme={direction === "rtl" ? themeRTL : theme}>
      <CssBaseline />
      {direction === "rtl" ? (
        <CacheProvider value={rtlCache}>
          {!isFullScreenRoute && layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="Institute Name"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              
              <Configurator />
              {configsButton}
            </>
          )}
          <Routes>
            <Route path="/signin" element={<SignIn />} /> {/* SignIn Route */}
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/signin" />} />
            <Route path="/readaloud" element={<ReadAloudComponent />} />
            <Route path="/readaloud/:id" element={<Readaloudquestions />} />
            <Route path="/repeatsentence" element={<RepeatSentence />} /> {/* New Route */}
          </Routes>
        </CacheProvider>
      ) : (
        <>
          {!isFullScreenRoute && layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="Institute Name"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/signin" />} />
            <Route path="/readaloud" element={<ReadAloudComponent />} />
            <Route path="/readaloud/:id" element={<Readaloudquestions />} />
            <Route path="/repeatsentence" element={<RepeatSentence />} />
          </Routes>
        </>
      )}
    </ThemeProvider>
  );
}
